const config = {
  company: 'vib europe' || 'vib GmbH',
  logoURI:
    'https://utils.vib-development.ch/vib-europe/logo.svg' || 'https://utils.vib-development.ch/vib/logo.svg',
  logoWhiteURI:
    'https://utils.vib-development.ch/vib-europe/logo-white.svg' || 'https://utils.vib-development.ch/vib/logo-white.svg',

  address: 'vib europe GmbH,Wilhelm-Sammet-Straße 87/89,D-04157 Leipzig'?.split(',') || [
    'vib GmbH',
    'Grabenstrasse 12',
    '6340 Baar',
  ],
  contact: '+49 175 6185760,ecm@vib-europe.de'?.split(',') || [
    '+41 (0)41 763 16 94',
    'support@vib-gmbh.ch',
  ],
  apibaseuri:
    'https://api.manual.vib-europe.de' ||
    'https://api.main.vib-connect-manual.ch4.amazee.io',
  appname: 'vib europe ECM Manual' || 'vib connect Manual',
  appdomain: 'manual.vib-europe.de' || 'manual.vib-gmbh.ch',
  appurl: 'https://manual.vib-europe.de' || 'https://manual.vib-gmbh.ch',
  statuspage: 'https://status.vib-development.ch' || 'https://status.vib-development.ch',
  support: 'ecm@vib-europe.de' || 'support@vib-gmbh.ch',
  root: '1F4Cjj3il6LuIo03iAlWKYUVg0segGiS1' || '',
  footer: '158bpHSqKVzBOu2ECRKFsSvBbTMxP_I3H' || '',
  disclaimer: '19e4yJ__lkpj21uJWDsxj3BBzOtTuLot6qJfJyijekhA' || '',
  languages: 'DE,EN-US'?.split(',') || ['DE','EN-GB'],
  screenBreak: '930' || 930
};

export default config;
